<template>
  <div class="TopPageSliderNavigator">
    <div
      class="TopPageSliderNavigator_Button prev"
      :class="{ deactive: Number(slideIndex) === 0 }"
      @click.stop="moveSlide(false)"
    ></div>
    <ul class="TopPageSliderNavigator_Paging">
      <li
        v-for="(slide, index) in slideNum"
        :key="`sldr_${index}`"
        :class="{ active: Number(slideIndex) === index }"
      />
    </ul>
    <div
      class="TopPageSliderNavigator_Button next"
      :class="{ deactive: Number(slideIndex) === slideCount - 1 }"
      @click.stop="moveSlide(true)"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    slideCount: {
      type: String,
      default: ''
    },
    current: {
      type: Number,
      default: 0
    },
    slideIndex: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      slideNum: Number(this.slideCount)
    }
  },
  methods: {
    moveSlide(next) {
      this.$emit('changeCurrent', next)
    }
  }
}
</script>
<style lang="postcss" scoped>
.TopPageSliderNavigator {
  margin: 12px auto 29px;
  display: flex;
  justify-content: space-between;
}
&_Button {
  width: 50px;
  height: 12px;
  font-size: 0;
  border-bottom: 1px solid #fff !important;
  &:focus {
    outline: none !important;
  }
  &.prev {
    left: 20px;
    border-left: 1px solid #fff !important;
    transform: skewX(-45deg) !important;
  }
  &.next {
    right: 20px;
    border-right: 1px solid #fff !important;
    transform: skewX(45deg) !important;
  }

  &_Paging {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 327px) {
      width: 35px !important;
    }
    li {
      box-sizing: border-box !important;
      width: 5px !important;
      height: 5px !important;
      padding: 0 !important;
      margin: 0 2.5px;
      border: 1px solid #fff !important;
      border-radius: 10px;
      &.active {
        background: #fff;
      }
    }
  }
}

.deactive {
  opacity: 0.5;
}
</style>
