<template>
  <div class="TopPagePricingSlider">
    <client-only>
      <carousel-3d
        v-if="!responsive && priceType === 'personal'"
        :width="679"
        :height="668"
        :perspective="0"
      >
        <slide-3d :index="0">
          <div class="TopPagePricingSlider_Slide">
            <h4 class="TopPagePricingSlider_TitleSp">
              <img src="/img/pricing-personal.png" class="width146" />
              <div class="TopPagePricingSlider_Text">
                <p class="TopPagePricingSlider_TextTitle">
                  <span>basic</span>
                  Free
                </p>
                <div class="TopPagePricingSlider_TextInner">
                  <p>
                    金額:<b><span class="big"> 0 </span>円/月</b>
                  </p>
                  <p class="medium">
                    すべてのアーティスト、音楽クリエイターの<br />皆さまにお使いいただけるプランです。
                  </p>
                  <p class="small">
                    ※ドメインおよび分析機能に一部制限があります。
                  </p>
                </div>
              </div>
            </h4>
            <div class="TopPagePricingSlider_TextDetail">
              <ul>
                <li>・全てのリンクページ作成機能</li>
                <li>・PVやクリック、地域など計測/分析機能</li>
                <li>・3種の期間指定にてデータ分析可能</li>
                <li>・QRコード出力対応</li>
                <li>・URL末尾のテキスト指定可能</li>
              </ul>
              <TopPageButton
                prime
                class="TopPagePricingSliderClose transparent"
              >
                <template #content>
                  <a @click="$emit('priceTableOpen', true)">
                    プラン別機能比較へ
                  </a>
                </template>
              </TopPageButton>
            </div>
            <TopPageButton prime class="TopPagePricingSliderClose">
              <template #content>
                <a href="/register">
                  新規無料メンバー登録
                </a>
              </template>
            </TopPageButton>
            <TopPageButton class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('pricingPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide-3d>
        <slide-3d :index="1">
          <div class="TopPagePricingSlider_Slide">
            <h4 class="TopPagePricingSlider_TitleSp">
              <img src="/img/pricing-personal.png" class="width146" />
              <div class="TopPagePricingSlider_Text">
                <p class="TopPagePricingSlider_TextTitle">
                  <span>basic</span>
                  Personal
                </p>
                <div class="TopPagePricingSlider_TextInner">
                  <p>
                    金額:<b><span class="big"> 1,650 </span>円/月</b>
                  </p>
                  <p class="medium">
                    年払い：17,820円（10%お得）<br />
                    B.O.Mの全ての機能をお使いいただけるプランです。
                  </p>
                  <p class="small">
                    ※独自のドメインを指定することができます。
                  </p>
                </div>
              </div>
            </h4>
            <div class="TopPagePricingSlider_TextDetail">
              <ul>
                <li>・全てのリンクページ作成機能</li>
                <li>・全ての計測/分析機能</li>
                <li>・任意の期間指定にてデータ分析可能</li>
                <li>・QRコード、計測データ外部出力対応</li>
                <li>・ドメイン、URL末尾のテキスト指定可能</li>
              </ul>
              <TopPageButton prime>
                <template #content>
                  <a href="/register">
                    新規無料メンバー登録
                  </a>
                </template>
              </TopPageButton>
            </div>
            <TopPageButton prime class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('priceTableOpen', true)">
                  プラン別機能比較へ
                </a>
              </template>
            </TopPageButton>
            <TopPageButton class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('pricingPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide-3d>
      </carousel-3d>
      <carousel-3d
        v-if="!responsive && priceType === 'business'"
        :width="679"
        :height="668"
        :perspective="0"
      >
        <slide-3d :index="0">
          <div class="TopPagePricingSlider_Slide">
            <h4 class="TopPagePricingSlider_TitleSp">
              <img src="/img/pricing-business.png" class="width133" />
              <div class="TopPagePricingSlider_Text">
                <p class="TopPagePricingSlider_TextTitle">
                  <span>business</span>
                  Enterprise
                </p>
                <div class="TopPagePricingSlider_TextInner">
                  <p>
                    金額:<b><span class="big"> ASK </span></b>
                  </p>
                  <p class="medium">
                    B.O.Mの全ての機能をお使いいただけるプランです。<br />
                    連携アカウント数、独自ドメイン数が無制限。
                  </p>
                </div>
              </div>
            </h4>
            <div class="TopPagePricingSlider_TextDetail">
              <ul>
                <li>・全てのリンク作成、計測/分析機能</li>
                <li>・連結アカウント数無制限</li>
                <li>・任意の期間指定にてデータ分析可能</li>
                <li>・QRコード、計測データ外部出力対応</li>
                <li>・ドメイン、URL末尾のテキスト指定可能</li>
              </ul>
              <TopPageButton prime>
                <template #content>
                  <a @click="$emit('priceTableOpen', true)">
                    プラン別機能比較へ
                  </a>
                </template>
              </TopPageButton>
            </div>
            <TopPageButton prime class="TopPagePricingSliderClose">
              <template #content>
                <a href="https://form.run/@bom--1592908099" target="_blank">
                  お問い合わせ
                </a>
              </template>
            </TopPageButton>
            <TopPageButton class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('pricingPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide-3d>
        <slide-3d :index="1">
          <div class="TopPagePricingSlider_Slide">
            <h4 class="TopPagePricingSlider_TitleSp">
              <img src="/img/pricing-business.png" class="width133" />
              <div class="TopPagePricingSlider_Text">
                <p class="TopPagePricingSlider_TextTitle">
                  <span>business</span>
                  Small Team
                </p>
                <div class="TopPagePricingSlider_TextInner">
                  <p>
                    金額:<b><span class="big"> ASK </span></b>
                  </p>
                  <p class="medium">
                    B.O.Mの全ての機能をお使いいただけるプランです。<br />
                    アカウント作成数、ドメイン指定数に制限があります。
                  </p>
                  <p class="small">
                    ※連結アカウント数が10アカウント以下の場合に推奨。
                  </p>
                </div>
              </div>
            </h4>
            <div class="TopPagePricingSlider_TextDetail">
              <ul>
                <li>・全てのリンク作成、計測/分析機能</li>
                <li>・10アカウントまで連結可能</li>
                <li>・任意の期間指定にてデータ分析可能</li>
                <li>・QRコード、計測データ外部出力対応</li>
                <li>・ドメイン、URL末尾のテキスト指定可能</li>
              </ul>
              <TopPageButton prime>
                <template #content>
                  <a @click="$emit('priceTableOpen', true)">
                    プラン別機能比較へ
                  </a>
                </template>
              </TopPageButton>
            </div>
            <TopPageButton prime class="TopPagePricingSliderClose">
              <template #content>
                <a href="https://form.run/@bom--1592908099" target="_blank">
                  お問い合わせ
                </a>
              </template>
            </TopPageButton>
            <TopPageButton class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('pricingPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide-3d>
      </carousel-3d>
      <carousel
        v-if="responsive && priceType === 'personal'"
        :per-page="1"
        :navigate-to="currentSlide1"
        :navigation-enabled="false"
        :pagination-enabled="false"
        :adjustable-height="true"
        @page-change="(e) => changeSlideOnSwap(e, 1)"
      >
        <slide>
          <div class="TopPagePricingSlider_Slide">
            <h4 class="TopPagePricingSlider_TitleSp">
              <img src="/img/pricing-personal.png" width="146" />
              <div class="TopPagePricingSlider_Text">
                <p class="TopPagePricingSlider_TextTitle">
                  <span>personal</span>
                  Free
                </p>
                <div class="TopPagePricingSlider_TextInner">
                  <p>
                    金額:<b><span class="big"> 0 </span>円/月</b>
                  </p>
                  <p class="medium">
                    すべてのアーティスト、音楽クリエイターの<br
                      class="sp"
                    />皆さまにお使いいただけるプランです。
                  </p>
                  <p class="small">
                    ※ドメインおよび分析機能に一部制限があります。
                  </p>
                </div>
              </div>
            </h4>
            <div class="TopPagePricingSlider_TextDetail">
              <ul>
                <li>・全てのリンクページ作成機能</li>
                <li>・PVやクリック、地域など計測/分析機能</li>
                <li>・3種の期間指定にてデータ分析可能</li>
                <li>・QRコード出力対応</li>
                <li>・URL末尾のテキスト指定可能</li>
              </ul>
              <TopPageButton prime>
                <template #content>
                  <a href="/register">
                    新規無料メンバー登録
                  </a>
                </template>
              </TopPageButton>
            </div>
            <TopPageSlideNavigator
              :current="currentSlide1"
              @changeCurrent="(e) => changeSlide(e, 1)"
              slide-count="2"
              slide-index="0"
            />
            <TopPageButton prime class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('priceTableOpen', true)">
                  プラン別機能比較へ
                </a>
              </template>
            </TopPageButton>
            <TopPageButton class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('pricingPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide>
        <slide>
          <div class="TopPagePricingSlider_Slide">
            <h4 class="TopPagePricingSlider_TitleSp">
              <img src="/img/pricing-personal.png" width="146" />
              <div class="TopPagePricingSlider_Text">
                <p class="TopPagePricingSlider_TextTitle">
                  <span>personal</span>
                  Free
                </p>
                <div class="TopPagePricingSlider_TextInner">
                  <p>
                    金額:<b><span class="big"> 1,650 </span>円/月</b>
                  </p>
                  <p class="medium">
                    年払い：17,820円（10%お得）<br
                      class="sp"
                    />B.O.Mの全ての機能をお使いいただけるプランです。
                  </p>
                  <p class="small">
                    ※独自のドメインを指定することができます。
                  </p>
                </div>
              </div>
            </h4>
            <div class="TopPagePricingSlider_TextDetail">
              <ul>
                <li>・全てのリンクページ作成機能</li>
                <li>・全ての計測/分析機能</li>
                <li>・任意の期間指定にてデータ分析可能</li>
                <li>・QRコード、計測データ外部出力対応</li>
                <li>・ドメイン、URL末尾のテキスト指定可能</li>
              </ul>
              <TopPageButton prime>
                <template #content>
                  <a href="/register">
                    新規無料メンバー登録
                  </a>
                </template>
              </TopPageButton>
            </div>
            <TopPageSlideNavigator
              :current="currentSlide1"
              @changeCurrent="(e) => changeSlide(e, 1)"
              slide-count="2"
              slide-index="1"
            />
            <TopPageButton prime class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('priceTableOpen', true)">
                  プラン別機能比較へ
                </a>
              </template>
            </TopPageButton>
            <TopPageButton class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('pricingPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide>
      </carousel>
      <carousel
        v-if="responsive && priceType === 'business'"
        :per-page="1"
        :navigate-to="currentSlide2"
        :navigation-enabled="false"
        :pagination-enabled="false"
        :adjustable-height="true"
        @page-change="(e) => changeSlideOnSwap(e, 2)"
      >
        <slide>
          <div class="TopPagePricingSlider_Slide">
            <h4 class="TopPagePricingSlider_TitleSp">
              <img src="/img/pricing-personal.png" width="146" />
              <div class="TopPagePricingSlider_Text">
                <p class="TopPagePricingSlider_TextTitle">
                  <span>Business</span>
                  Small Team
                </p>
                <div class="TopPagePricingSlider_TextInner">
                  <p>
                    金額:<b><span class="big"> ASK </span></b>
                  </p>
                  <p class="medium">
                    B.O.Mの全ての機能をお使いいただけるプランです。<br />
                    アカウント作成数、ドメイン指定数に制限があります。
                  </p>
                  <p class="small">
                    ※連結アカウント数が10アカウント以下の場合に推奨。
                  </p>
                </div>
              </div>
            </h4>
            <div class="TopPagePricingSlider_TextDetail">
              <ul>
                <li>・全てのリンク作成、計測/分析機能</li>
                <li>・10アカウントまで連結可能</li>
                <li>・任意の期間指定にてデータ分析可能</li>
                <li>・QRコード、計測データ外部出力対応</li>
                <li>・ドメイン、URL末尾のテキスト指定可能</li>
              </ul>
              <TopPageButton prime>
                <template #content>
                  <a href="https://form.run/@bom--1592908099" target="_blank">
                    お問い合わせ
                  </a>
                </template>
              </TopPageButton>
            </div>
            <TopPageSlideNavigator
              :current="currentSlide2"
              @changeCurrent="(e) => changeSlide(e, 2)"
              slide-count="2"
              slide-index="0"
            />
            <TopPageButton prime class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('priceTableOpen', true)">
                  プラン別機能比較へ
                </a>
              </template>
            </TopPageButton>
            <TopPageButton class="TopPagePricingSliderClose">
              <template #content>
                <a @click="$emit('pricingPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide>
        <slide>
          <div class="TopPagePricingSlider_Slide">
            <h4 class="TopPagePricingSlider_TitleSp">
              <img src="/img/pricing-business.png" width="133" />
              <div class="TopPagePricingSlider_Text">
                <p class="TopPagePricingSlider_TextTitle">
                  <span>business</span>
                  Enterprise
                </p>
                <div class="TopPagePricingSlider_TextInner">
                  <p>
                    金額:<b><span class="big"> ASK </span></b>
                  </p>
                  <p class="medium">
                    B.O.Mの全ての機能をお使いいただけるプランです。<br />
                    連携アカウント数、独自ドメイン数が無制限。
                  </p>
                </div>
              </div>
            </h4>
            <div class="TopPagePricingSlider_TextDetail">
              <ul>
                <li>・全てのリンクページ作成機能</li>
                <li>・連結アカウント数無制限</li>
                <li>・3種の期間指定にてデータ分析可能</li>
                <li>・QRコード出力対応</li>
                <li>・URL末尾のテキスト指定可能</li>
              </ul>
              <TopPageButton prime>
                <template #content>
                  <a href="https://form.run/@bom--1592908099" target="_blank">
                    お問い合わせ
                  </a>
                </template>
              </TopPageButton>
            </div>
            <TopPageSlideNavigator
              :current="currentSlide2"
              @changeCurrent="(e) => changeSlide(e, 2)"
              slide-count="2"
              slide-index="1"
            />
            <TopPageButton prime class="TopPagePricingSlider_Close">
              <template #content>
                <a @click="$emit('priceTableOpen', true)">
                  プラン別機能比較へ
                </a>
              </template>
            </TopPageButton>
            <TopPageButton class="TopPagePricingSlider_Close">
              <template #content>
                <a @click="$emit('pricingPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide>
      </carousel>
    </client-only>
  </div>
</template>
<script>
import TopPageButton from './TopPageButton.vue'
import TopPageSlideNavigator from './TopPageSlideNavigator.vue'

export default {
  components: {
    TopPageButton,
    TopPageSlideNavigator
  },
  props: {
    priceType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      responsive: false,
      currentSlide1: 0,
      currentSlide2: 0,
      slideNum: 2
    }
  },
  mounted() {
    if (process.browser) {
      if (window.innerWidth < 751) {
        this.responsive = true
      }
      setTimeout(() => {
        this.applyCurrentSlideHeight()
      }, 100)
    }
  },
  methods: {
    changeSlide(e, slideId) {
      if (slideId === 1) {
        if (e && this.currentSlide1 <= this.slideNum - 1) {
          this.currentSlide1 += 1
        }
        if (!e && this.currentSlide1 > 0) {
          this.currentSlide1 -= 1
        }
      }
      if (slideId === 2) {
        if (e && this.currentSlide2 <= this.slideNum - 1) {
          this.currentSlide2 += 1
        }
        if (!e && this.currentSlide2 > 0) {
          this.currentSlide2 -= 1
        }
      }
    },
    changeSlideOnSwap(e, slideId) {
      if (slideId === 1) this.currentSlide1 = e
      if (slideId === 2) this.currentSlide2 = e
    },
    applyCurrentSlideHeight() {
      const current = document.getElementsByClassName(
        'VueCarousel-slide-active'
      )[0]
      const currentSlide =
        current || document.getElementsByClassName('VueCarousel-slide')[0]
      const slideHeight = document.getElementsByClassName(
        'VueCarousel-inner'
      )[0]
      if (slideHeight === currentSlide.offsetHeight) {
        return
      }
      slideHeight.style.height = `${currentSlide.offsetHeight + 1}px`
    }
  }
}
</script>
<style lang="postcss" scoped>
.TopPagePricingSlider {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 27px 0;
  overflow: auto;
  background: rgba(33, 33, 33, 1);
  @media screen and (min-width: 751px) {
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  &_Slide {
    position: relative;
    padding: 41px 24px 51px;
    margin: 0 24px;
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(33, 33, 33, 1) 100%
    );
    border-radius: 100px;
    @media screen and (min-width: 751px) {
      width: 679px;
      height: 668px;
      padding: 30px 70px;
      margin: auto;
      border-radius: 100px;
    }
  }
  &_Text {
    position: relative;
    z-index: 1;
    @media screen and (min-width: 751px) {
      width: 383px;
    }
    &Title {
      padding: 62px 0 54px;
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 0.1em;
      @media screen and (min-width: 751px) {
        padding: 0;
        font-size: 23px;
        text-align: left;
      }
      span {
        display: block;
        font-size: 12px;
        font-weight: medium;
        color: #ffc107;
        text-transform: uppercase;
        @media screen and (min-width: 751px) {
          display: inline-block;
          padding-right: 11px;
          margin-right: 11px;
          font-size: 23px;
          border-right: 1px solid #424242;
        }
      }
    }
    &Inner {
      position: relative;
      min-height: 140px;
      font-size: 14px;
      @media screen and (min-width: 751px) {
        min-height: 50px;
        padding-bottom: 27.5px;
        font-size: 14px;
        text-align: left;
      }
      &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 72px;
        height: 1px;
        margin: auto;
        content: '';
        background: #424242;
        @media screen and (min-width: 751px) {
          display: none;
        }
      }
      .big {
        font-size: 40px;
        @media screen and (min-width: 751px) {
          font-size: 40px;
        }
      }
      .medium {
        margin-top: 10px;
        font-size: 12px;
        line-height: 2;
        @media screen and (min-width: 751px) {
          margin-top: 10px;
          font-size: 12px;
          line-height: 18px;
        }
      }
      .small {
        margin-top: 3px;
        font-size: 10px;
        color: #888;
        @media screen and (min-width: 751px) {
          margin-top: 7px;
          font-size: 10px;
        }
      }
    }
  }
  h4 {
    position: relative;
    text-align: center;
    @media screen and (min-width: 751px) {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #424242;
    }
    img {
      position: absolute;
      right: 0;
      left: 0;
      height: 159px;
      margin: auto;
      opacity: 0.3;
      @media screen and (min-width: 751px) {
        position: relative;
        height: auto;
        margin-top: 0;
        opacity: 1;
      }
    }
  }
  &_TextDetail {
    margin: 18px auto 0;
    @media screen and (min-width: 751px) {
      margin: 27.5px auto 14px;
    }
    ul {
      margin-bottom: 20px;
      @media screen and (min-width: 751px) {
        margin-bottom: 53px;
      }
    }
    li {
      font-size: 14px;
      line-height: 1.9;
      @media screen and (min-width: 751px) {
        font-size: 18px;
        line-height: 2;
      }
    }
    a {
      background: rgba(255, 193, 7, 0.25);
      @media screen and (min-width: 751px) {
        width: 100% !important;
        height: 50px;
        background-color: transparent;
      }
    }
    .ButtonComponent {
      width: 100%;
      margin-left: 0;
      @media screen and (min-width: 751px) {
        margin-bottom: 14px;
      }
      a {
        @media screen and (min-width: 751px) {
          font-size: 14px;
        }
      }
    }
    .transparent a {
      background: transparent !important;
    }
  }
  &Close {
    width: 158px;
    margin: 0 auto 12px;
    text-align: center;
    @media screen and (min-width: 751px) {
      width: 100%;
      margin: 0 auto 32px;
      a {
        width: 158px;
        cursor: pointer;
      }
    }
    a {
      width: 158px;
      height: 30px;
      margin: auto;
      font-size: 12px;
      line-height: 30px;
      border-radius: 5px;
      @media screen and (min-width: 751px) {
        width: 158px;
        height: 30px;
        padding: 0;
        font-size: 12px;
        line-height: 30px;
        cursor: pointer;
        border-radius: 5px;
      }
    }
    &.ButtonComponent.ButtonPrime a {
      width: 100%;
      @media screen and (min-width: 751px) {
        height: 50px;
        font-size: 14px;
        background: rgba(255, 193, 7, 0.25);
      }
    }
  }
}
.carousel-3d {
  &-slide {
    visibility: inherit;
    background: none;
    border-color: transparent;
    opacity: 1;
  }
  &-container,
  &-slider,
  &-slide {
    height: auto !important;
  }
}

.width146 {
  @media screen and (min-width: 751px) {
    width: 146px;
  }
}

.width133 {
  @media screen and (min-width: 751px) {
    width: 133px;
  }
}
</style>
