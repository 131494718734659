<template>
  <div class="TopMain">
    <div>
      <client-only>
        <div id="fullpage">
          <div class="section">
            <div class="TopMain_Wrapper">
              <top-page-main-visual />
            </div>

            <!-- <div class="TopMain_SideBanner">
              <div class="TopMain_SideBannerVideo">
                <img src="/img/sidebanner.jpg" />
              </div>
              <div classs="TopMain_SideBannerText">
                <div class="TopMain_SideBannerTextInner">
                  <p class="TopMain_SideBannerText--Sub">
                    解説動画
                  </p>
                  <p class="TopMain_SideBannerTextTitle">
                    動画タイトル入る動画タイトル入る動画タイトル入る
                  </p>
                </div>
              </div>
            </div> -->
          </div>
          <div class="section sectionLink fp-auto-height skip">
            <div class="formWrapper">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(scan)">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="URL"
                    rules="required|url-service-scan"
                  >
                    <div class="LinkCreate">
                      <img src="/img/link-icon.png" />
                      <div class="LinkCreate_Block">
                        <div class="LinkCreate_BlockText">
                          <p>
                            会員登録ナシ<span
                              >で<span class="pc">まずは</span></span
                            >お試し！<br class="sp" />簡単リンク作成
                          </p>
                          <button
                            type="button"
                            class="TopMain_ButtonCreateSp"
                            @click="linkCreateSp = true"
                          >
                            リンクを<br />試す
                          </button>
                        </div>
                        <div v-if="!responsive" class="LinkCreate_FormBlock">
                          <div class="LinkCreate_BlockForm">
                            <input
                              v-model="serviceUrl"
                              class="TopMain_InputElement"
                              type="text"
                              placeholder="好きな曲のSpotify / Apple MusicのURLを入力するだけ"
                            />
                            <button type="submit" class="TopMain_ButtonCreate">
                              作成
                            </button>
                          </div>
                          <p class="TopMain_InputError">
                            {{ errors[0] }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </ValidationProvider>
                </form>
              </ValidationObserver>
            </div>
          </div>
          <div class="section TopMain_Wrapper skipNext">
            <top-page-about />
          </div>
          <div
            class="section TopMain_Wrapper"
            :class="{ 'fp-auto-height': responsive }"
          >
            <top-page-function
              @popupShow="showPopup(true)"
              @priceTableOpen="
                priceTable = true
                priceTableOpen = true
              "
            />
          </div>
          <div
            class="section TopMain_Wrapper"
            :class="{ 'fp-auto-height': responsive }"
          >
            <top-page-pricing
              @popupShow="showPopupPrice"
              @priceTableOpen="priceTable = true"
            />
          </div>
          <div class="section TopMain_Wrapper">
            <top-page-statement :responsive="responsive" @rebuild="fpRebuild" />
          </div>
          <div class="section TopMain_Wrapper section7">
            <top-page-topics :note="noteData" />
          </div>
          <div class="section fp-auto-height">
            <TheTeaserFooter />
          </div>
        </div>
      </client-only>
      <div class="TopMain_ScrollMark">Scroll</div>
      <template v-if="popup">
        <top-page-function-slide
          @functionPopupClose="showPopup(false)"
          @showFunction="
            showPopup(false)
            priceTable = true
          "
        />
      </template>
      <template v-if="popupPrice">
        <top-page-pricing-slide
          :price-type="priceType"
          @priceTableOpen="priceTable = true"
          @pricingPopupClose="popupPrice = false"
        />
      </template>
    </div>
    <div v-show="showModal" class="TopMain_ModalWrapper">
      <div class="TopMain_ModalAddCard">
        <div class="TopMain_ModalHead">
          リンクページの確認
        </div>
        <div class="TopMain_ModalBody">
          <p class="TopMain_ModalText">
            リンクページが作成されました。<br />
            以下のURLからご確認ください。
          </p>
          <div class="TopMain_ModalLinkWrapper">
            <a class="TopMain_ModalLink" :href="newUrl" target="_blank">
              {{ newUrl }}
            </a>
          </div>
          <div class="TopMain_ModalIframeWrapper">
            <p class="TopMain_ModalIframeText">埋め込みコード：</p>
            <div
              v-clipboard:copy="iframe"
              v-clipboard:success="copiedSuccess"
              class="TopMain_ModalIframe"
            >
              <code class="TopMain_ModalCode">
                {{ iframe }}
              </code>
            </div>
          </div>
          <div class="TopMain_ModalButtonWrapper">
            <button
              class="TopMain_ModalCancel"
              @click="
                showModal = false
                serviceUrl = ''
              "
            >
              閉じる
            </button>
            <n-link
              v-if="data && data.linkId"
              :to="{
                path: `/non-registered/edit?linkId=${data.linkId}${
                  $device.isDesktop ? '&isPreviewLink=true' : ''
                }`
              }"
              class="TopMain_ModalSubmit"
            >
              編集する
            </n-link>
          </div>
        </div>
      </div>
    </div>
    <client-only>
      <div v-show="responsive && linkCreateSp" class="TopMain_LinkCreateSp">
        <div class="TopMain_LinkCreateSpBlock">
          <img src="/img/link-icon.png" />
          <p>
            好きな曲のSpotify / Apple
            MusicのURLを入力して作成ボタンを押してください。
          </p>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(scan)">
              <ValidationObserver
                v-slot="{ errors }"
                name="URL"
                rules="required|url-service-scan"
              >
                <input
                  v-model="serviceUrl"
                  class="TopMain_InputElement"
                  type="text"
                  placeholder="URLを入力"
                />
                <p class="TopMain_InputError">
                  {{ errors[0] }}
                </p>
                <button type="submit" class="TopMain_ButtonCreate">
                  作成
                </button>
              </ValidationObserver>
            </form>
          </ValidationObserver>
          <Button
            class="TopPage_LinkCreateSpClose"
            @click="linkCreateSp = false"
          >
            閉じる
          </Button>
        </div>
      </div>
      <top-page-pricing-table-pc
        v-if="!responsive && priceTable"
        @priceClose="priceTable = false"
      />
      <top-page-pricing-table-sp
        v-if="responsive && priceTable"
        @priceClose="priceTable = false"
      />
    </client-only>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import TopPageMainVisual from '../components/TopPageMainVisual.vue'
import TopPageAbout from '../components/TopPageAbout.vue'
import TopPageFunction from '../components/TopPageFunction.vue'
import TopPagePricing from '../components/TopPagePricing.vue'
import TopPageStatement from '../components/TopPageStatement.vue'
import TopPageTopics from '../components/TopPageTopics.vue'
import TopPageFunctionSlide from '../components/TopPageFunctionSlide.vue'
import TopPagePricingSlide from '../components/TopPagePricingSlide.vue'
import TopPagePricingTablePc from '../components/TopPagePricingTablePc.vue'
import TopPagePricingTableSp from '../components/TopPagePricingTableSp.vue'
import TheTeaserFooter from '~/components/TheTeaserFooter'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const LambdaBomRepository = RepositoryFactory.get('lambda')
const AppleApiRepository = RepositoryFactory.get('apple')
const SpotifyApiRepository = RepositoryFactory.get('spotify')
const EmptyRepository = RepositoryFactory.get('empty')
const NonRegisteredRepository = RepositoryFactory.get('nonRegistered')

export default {
  layout: 'teaser-top',
  components: {
    TopPageMainVisual,
    TopPageAbout,
    TopPageFunction,
    TopPagePricing,
    TopPageStatement,
    TopPageTopics,
    TopPageFunctionSlide,
    TopPagePricingSlide,
    TopPagePricingTablePc,
    TopPagePricingTableSp,
    TheTeaserFooter
  },
  data() {
    return {
      checkTermsOfService: false,
      serviceUrl: '',
      copyMessage: false,
      showModal: false,
      urlDomain: process.env.LINK_PAGE_DEFAULT_DOMAIN,
      urlPath: '',
      newUrl: '',
      iframe: '',
      data: null,
      currentPage: 0,
      popup: false,
      popupPrice: false,
      options: {
        licenseKey: process.env.FULL_PAGE_JS_LICENSE_KEY,
        navigation: true,
        afterLoad: this.inViewActions,
        responsiveWidth: 751,
        normalScrollElements:
          '.TopPagePricingTablePc_Inner, .TopMain_LinkCreateSpBlock, .TopMain_LinkCreateSpBlock, .TopPageTopics_News-Note--List',
        onLeave: this.skipPages,
        bigSectionsDestination: 'top'
      },
      responsive: false,
      linkCreateSp: false,
      priceTable: false,
      priceType: '',
      currentSection: ''
    }
  },
  watch: {
    '$store.state.toppage.currentSection'() {
      if (this.$store.state.toppage.currentSection !== 1) {
        setTimeout(() => {
          window.fullpage_api.moveTo(this.$store.state.toppage.currentSection)
        })
        sessionStorage.removeItem('topPageCurrentSection')
      }
    }
  },
  async asyncData({ app, query }) {
    const noteData = await app.$axios.$get('/api/note/posts')

    if (query.path && query.linkId) {
      const newUrl =
        'https://' + process.env.LINK_PAGE_DEFAULT_DOMAIN + '/' + query.path
      const iframe =
        '<iframe src="' +
        newUrl +
        '?embed=true" width="100%" height="220" frameborder="0"></iframe>'
      const showModal = true
      const linkDataRes = await NonRegisteredRepository.getLinkById(
        query.linkId
      )
      const data = linkDataRes.data
      return { urlPath: query.path, newUrl, iframe, showModal, data, noteData }
    }

    return { noteData }
  },
  async mounted() {
    await this.$nextTick()
    // HACK: windowイベントが発火しないと画像が初期表示されないvue-carouselの不具合に対応
    const resizeEvent = window.document.createEvent('UIEvents')
    resizeEvent.initUIEvent('resize', true, false, window, 0)
    window.dispatchEvent(resizeEvent)

    if (process.browser) {
      this.$fullPage('#fullpage', this.options)
      this.moveToSection()
      if (window.innerWidth < 751) {
        this.responsive = true
      }
    }
  },
  methods: {
    copiedSuccess() {
      window.alert('コピーしました')
    },
    async scan() {
      this.$nuxt.$loading.start()
      try {
        const path = await NonRegisteredRepository.getRandomPath()
        if (path.status === 200) {
          this.urlPath = 'nr-' + path.data
        }
      } catch (e) {
        throw new Error(e)
      }
      let params = {}
      this.serviceUrl = decodeURIComponent(this.serviceUrl)
      if (this.serviceUrl.match(/spotify/)) {
        try {
          const tokenRes = await LambdaBomRepository.getAccessToken('spotify')
          if (!tokenRes.data) {
            throw new Error('Spotifyトークン取得エラー')
          }
          const token = tokenRes.data
          if (this.serviceUrl.match(/link.tospotify.com/)) {
            const res = await EmptyRepository.getByUrl(this.serviceUrl)
            this.serviceUrl = res.request.res.responseUrl
          }
          this.serviceUrl.replace(/\?si=.*/, '')
          const urlArray = this.serviceUrl.match(
            /https:\/\/open.spotify.com\/(?:intl-[a-z]{2}\/)?(album|playlist|track)\/(.*)/
          )
          const SpotifyRes = await SpotifyApiRepository.getInfo(
            urlArray[1],
            urlArray[2],
            {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          )
          if (!SpotifyRes.data) {
            throw new Error('Spotifyデータ取得エラー')
          }
          const response = SpotifyRes.data
          const artists = []
          if (urlArray[1] !== 'playlist') {
            for (let index = 0; index < response.artists.length; index++) {
              artists.push(response.artists[index].name)
            }
          }
          const imageUrl =
            urlArray[1] === 'album' || urlArray[1] === 'playlist'
              ? response.images[0].url
              : response.album.images[0].url
          const linkType = urlArray[1]
          const audioPreviewUrl =
            response.type === 'track'
              ? response.previewUrl || ''
              : response.type === 'album'
              ? response.tracks.items[0].previewUrl || ''
              : ''
          params = {
            artists,
            imageUrl,
            linkType,
            serviceUrl: this.serviceUrl,
            urlDomain: this.urlDomain,
            urlPath: this.urlPath,
            title: response.name,
            audioPreviewUrl
          }
        } catch (e) {
          throw new Error(e)
        }
      } else if (this.serviceUrl.match(/music.apple.com/)) {
        try {
          const tokenRes = await LambdaBomRepository.getAccessToken(
            'apple-music'
          )
          if (!tokenRes.data) {
            throw new Error('Apple Musicトークン取得エラー')
          }
          const token = tokenRes.data
          if (
            this.serviceUrl.match(
              /https:\/\/music.apple.com\/(.*?)\/album\/.*?\/([0-9]+)\?i=([0-9]+)/
            )
          ) {
            const urlArray = this.serviceUrl.split(
              /https:\/\/music.apple.com\/(.*?)\/album\/.*?\/([0-9]+)\?i=([0-9]+)/
            )
            const responseObj = await AppleApiRepository.getCatalogInfo(
              urlArray[1],
              urlArray[3],
              'songs',
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
              }
            )
            const response = responseObj.data.data[0]
            const audioPreviewUrl =
              response.type === 'songs'
                ? response.attributes.previews[0].url || ''
                : response.type === 'albums'
                ? response.relationships.tracks.data[0].attributes.previews[0]
                    .url || ''
                : ''
            params = {
              artists: [response.attributes.artistName],
              imageUrl: response.attributes.artwork.url.replace(
                /{w}x{h}/,
                '300x300'
              ),
              linkType: ['track'],
              serviceUrl: this.serviceUrl,
              urlDomain: this.urlDomain,
              urlPath: this.urlPath,
              title: response.attributes.name,
              audioPreviewUrl
            }
          } else if (
            this.serviceUrl.match(
              /https:\/\/music.apple.com\/(.*?)\/album\/(?:(.*?)+\/([0-9]+)|([0-9]+))/
            )
          ) {
            const urlArray = this.serviceUrl.split(
              /https:\/\/music.apple.com\/|\/album\/|\//
            )
            const responseObj = await AppleApiRepository.getCatalogInfo(
              urlArray[1],
              urlArray.slice(-1)[0],
              'albums',
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
              }
            )
            const response = responseObj.data.data[0]
            const audioPreviewUrl =
              response.type === 'songs'
                ? response.attributes.previews[0].url || ''
                : response.type === 'albums'
                ? response.relationships.tracks.data[0].attributes.previews[0]
                    .url || ''
                : ''
            params = {
              artists: [response.attributes.artistName],
              imageUrl: response.attributes.artwork.url.replace(
                /{w}x{h}/,
                '300x300'
              ),
              linkType: ['track'],
              serviceUrl: this.serviceUrl,
              urlDomain: this.urlDomain,
              urlPath: this.urlPath,
              title: response.attributes.name,
              audioPreviewUrl
            }
          } else if (
            this.serviceUrl.match(
              /https:\/\/music.apple.com\/(.*?)\/playlist\/.*?\/(pl\.[a-zA-Z0-9\-_]+)/
            )
          ) {
            const urlArray = this.serviceUrl.split(
              /https:\/\/music.apple.com\/(.*?)\/playlist\/.*?\/(pl\.[a-zA-Z0-9\-_]+)/
            )
            const responseObj = await AppleApiRepository.getCatalogInfo(
              urlArray[1],
              urlArray[2],
              'playlists',
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
              }
            )
            const response = responseObj.data.data[0]

            params = {
              artists: [],
              imageUrl: response.attributes.artwork.url.replace(
                /{w}x{h}/,
                '300x300'
              ),
              linkType: ['playlist'],
              serviceUrl: this.serviceUrl,
              urlDomain: this.urlDomain,
              urlPath: this.urlPath,
              title: response.attributes.name,
              audioPreviewUrl: ''
            }
          }
        } catch (e) {
          throw new Error(e)
        }
      } else {
        params = {
          artists: [],
          imageUrl: `${process.env.BASE_URL}/img/no-image.png`,
          linkType: ['track', 'album'],
          serviceUrl: this.serviceUrl,
          urlDomain: this.urlDomain,
          urlPath: this.urlPath,
          title: null
        }
      }

      params.services = this.$getServiceObject(this.serviceUrl)
      params.description = ''
      params.releaseDate = moment().format('YYYY-MM-DDTHH:mm:ssZ')
      params.tags = []
      try {
        const postLink = await NonRegisteredRepository.postLinks(params)
        if (postLink.status === 200) {
          this.newUrl = 'https://' + this.urlDomain + '/' + this.urlPath
          this.iframe =
            '<iframe src="' +
            this.newUrl +
            '?embed=true" width="100%" height="220" frameborder="0"></iframe>'
          this.data = postLink.data
          this.$nuxt.$loading.finish()
          this.showModal = true
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    inViewActions() {
      const current = document.getElementsByClassName('section')

      Array.from(current).forEach((item, index) => {
        if (item.classList.contains('active')) {
          this.currentPage = index
        }
      })
      const section = document.getElementsByClassName('section')
      const currentSection = section[this.currentPage]
      if (!currentSection.classList.contains('sectionActive')) {
        currentSection.classList.add('sectionActive')
      }
    },
    showPopup(show) {
      this.popup = show
    },
    showPopupPrice(type) {
      this.popupPrice = true
      this.priceType = type
    },
    skipPages(origin, destination, direction) {
      this.updateSection({ section: 1 })
      if (
        window.fullpage_api &&
        origin.item.classList.contains('skipNext') &&
        !this.responsive &&
        direction === 'up'
      ) {
        setTimeout(() => {
          window.fullpage_api.moveTo(1)
        })
      }
    },
    fpRebuild() {
      if (window.fullpage_api) {
        window.fullpage_api.reBuild()
      }
    },
    moveToSection() {
      const current = sessionStorage.getItem('topPageCurrentSection')
      if (current) {
        setTimeout(() => {
          window.fullpage_api.moveTo(current)
        }, 1000)
        sessionStorage.removeItem('topPageCurrentSection')
      }
    },
    ...mapMutations('toppage', ['updateSection'])
  }
}
</script>
<style lang="postcss" scoped>
@import '@/assets/css/variables';
section {
  position: relative;
  z-index: 1;
}
.TopMain {
  &_Wrapper {
    z-index: 2;
    width: 327px !important;
    margin: auto;
    @media screen and (min-width: 1206px) {
      width: 1206px !important;
    }
  }
  &_ScrollMark {
    position: fixed;
    right: 10px;
    bottom: 156px;
    font-family: 'Avenir Next';
    font-size: 15px;
    font-style: italic;
    font-weight: bold;
    transition: all 0.5s ease-out;
    transform: rotate(90deg);
    &:after {
      position: absolute;
      right: 0;
      bottom: 6px;
      width: 100px;
      height: 20px;
      content: '';
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: translateX(100px) skewX(45deg);
      @media screen and (min-width: 751px) {
        bottom: 5px;
        width: 130px;
        height: 30px;
        transform: translateX(120px) skewX(45deg);
      }
    }
    @media screen and (min-width: 751px) {
      right: 58px;
      bottom: 175px;
      font-size: 20px;
    }
  }
  &_SideBanner {
    position: absolute;
    left: 0;
    display: none;
    background: #fff;
    transition: all 0.5s linear;
    @media screen and (min-width: 751px) {
      bottom: 36px;
      display: flex;
      width: 310px;
      border-radius: 5px;
    }
    &Video {
      img {
        @media screen and (min-width: 751px) {
          width: 127px;
          max-width: 127px;
        }
      }
    }
    &Text {
      &--Sub {
        font-family: 'Zen Kaku Gothic New';
        color: #03a6a6;
        @media screen and (min-width: 751px) {
          margin-bottom: 5px;
          font-size: 10px;
        }
      }
      &Title {
        font-family: 'Zen Kaku Gothic New';
        font-weight: bold;
        color: #212121;
        @media screen and (min-width: 751px) {
          font-size: 12px;
          line-height: 1.5;
        }
      }
    }
  }
  &_LinkCreateSp {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    padding: 0 24px;
    background: rgba(33, 33, 33, 0.8);
    &Block {
      padding: 44px 25px;
      font-size: 12px;
      text-align: center;
      background: #000;
      border-radius: 100px;
      box-shadow: 8px 0 10px rgba(0, 0, 0, 0.64);
    }
    img {
      margin: auto;
    }
    p {
      line-height: 2;
      text-align: left;
    }
    input {
      width: 100%;
      height: 50px;
      padding: 10px 15px;
      margin: 17px auto 14px;
      font-size: 18px;
      color: #888;
      background: #212121;
      border-radius: 10px;
      &::placeholder {
        color: #888;
      }
    }
    button {
      display: block;
      width: 158px;
      height: 30px;
      margin: 10px auto 0;
      border: 1px solid #fff;
      border-radius: 5px;
    }
    .TopMain_ButtonCreate {
      background: rgba(3, 166, 166, 0.7);
      border: 1px solid #03a6a6;
    }
  }
}
.LinkCreate {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  @media screen and (min-width: 751px) {
    padding: 10px 0;
  }
  img {
    width: 61px;
    @media screen and (min-width: 751px) {
      width: auto;
    }
  }
  p {
    font-size: 18px;
    line-height: 1.3;
    span {
      font-size: 12px;
    }
    @media screen and (min-width: 751px) {
      width: 100%;
      margin-bottom: 10px;
      font-size: 27px;
      font-weight: bold;
      text-align: left;
      span {
        font-size: 18px;
      }
    }
  }
  .TopMain_InputError {
    @media screen and (min-width: 751px) {
      font-size: 14px;
    }
  }
  input {
    background: #212121;
    @media screen and (min-width: 751px) {
      width: 494px;
      padding: 13px;
      border-radius: 10px;
    }
    &::placeholder {
      color: #888;
    }
  }
  button {
    background: rgba(3, 166, 166, 0.7);
    @media screen and (min-width: 751px) {
      padding: 13px 45px;
      font-size: 18px;
      border: 2px solid #03a6a6;
      border-radius: 10px;
    }
  }
  &_Block {
    &Text {
      display: flex;
      align-items: center;
      padding: 10px 0 8px;
      @media screen and (min-width: 751px) {
        padding: 0;
        margin-left: 0;
      }
    }
  }
  .TopMain_ButtonCreate {
    &Sp {
      width: 81px;
      height: 41px;
      margin: 0 10px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.2;
      color: #ffc107;
      background: rgba(255, 193, 7, 0.2);
      border: 1px solid #ffc107;
      border-radius: 10px;
      @media screen and (min-width: 751px) {
        display: none;
      }
    }
  }
  &_FormBlock {
    @media screen and (max-width: 750px) {
      display: none;
    }
  }
}
.TopMain {
  &_ModalWrapper {
    @util position(fixed, 0 0 0 0);
    z-index: var(--zi-themodal);
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: var(--c-black-1);
    @media (min-width: 751px) {
      z-index: var(--zi-member-modal-wrapper);
    }
  }
  &_ModalAddCard {
    width: 360px;
    min-height: 396px;
    @util center;
    @media (min-width: 751px) {
      width: 579px;
      min-height: 396px;
    }
  }
  &_ModalHead {
    padding: 11.5px 0;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    text-align: center;
    background-color: var(--c-green-8);
    @util border-top-radius(10px);
    @media (min-width: 751px) {
      padding: 11.5px 0;
      font-size: 18px;
      @util border-top-radius(10px);
    }
  }
  &_ModalBody {
    padding: 20px 20px 30px;
    font-size: 18px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    background-color: var(--c-black-base);
    border-right: 1px solid var(--c-gray-5);
    border-bottom: 1px solid var(--c-gray-5);
    border-left: 1px solid var(--c-gray-5);
    @util border-bottom-radius(10px);
    @media (min-width: 751px) {
      padding: 40px 73px;
      font-size: 18px;
      border-right: 1px solid var(--c-gray-5);
      border-bottom: 1px solid var(--c-gray-5);
      border-left: 1px solid var(--c-gray-5);
      @util border-bottom-radius(10px);
    }
  }
  &_ModalText {
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    line-height: 28px;
    text-align: center;
    @media (min-width: 751px) {
      font-size: 14px;
      line-height: 28px;
    }
  }
  &_ModalLinkWrapper {
    padding: 15px 0 25px;
    text-align: center;
    @media (min-width: 751px) {
      padding: 15px 0 25px;
    }
  }
  &_ModalLink {
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    line-height: 1.6;
    color: var(--c-green-1);
    @media (min-width: 751px) {
      font-size: 18px;
    }
  }
  &_ModalIframeWrapper {
    width: 320px;
    margin: 0 auto 15px;
    @media (min-width: 751px) {
      width: 100%;
      margin: 0 auto 30px;
    }
  }
  &_ModalIframeText {
    font-size: 14px;
    line-height: 28px;
    @media (min-width: 751px) {
      font-size: 14px;
      line-height: 28px;
    }
  }
  &_ModalIframe {
    padding: 7px 10px 3px;
    margin-top: 6px;
    cursor: pointer;
    border: 2px solid var(--c-gray-1);
    @media (min-width: 751px) {
      padding: 7px 10px 3px;
      margin-top: 6px;
      border: 2px solid var(--c-gray-1);
    }
  }
  &_ModalCode {
    font-size: 14px;
    font-weight: var(--font-weight-light);
    line-height: 28px;
    overflow-wrap: break-word;
    white-space: initial;
    @media (min-width: 751px) {
      font-size: 14px;
      line-height: 28px;
    }
  }
  &_ModalButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media (min-width: 751px) {
      justify-content: space-between;
      width: 380px;
      margin: 0 auto;
    }
  }
  &_ModalCancel {
    display: block;
    width: 38%;
    height: 50px;
    padding: 13px 0;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    border: 2px solid var(--c-gray-1);
    border-radius: 50px;
    @media (min-width: 751px) {
      width: 180px;
      height: 50px;
      padding: 13px 0;
      margin: auto;
      font-size: 14px;
      border: 2px solid var(--c-gray-1);
    }
  }
  &_ModalSubmit {
    display: block;
    width: 38%;
    height: 50px;
    padding: 15px 0 13px;
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    color: var(--c-white-base);
    text-align: center;
    border: 2px solid var(--c-green-1);
    border-radius: 50px;
    @media (min-width: 751px) {
      width: 180px;
      height: 50px;
      padding: 15px 0 13px;
      font-size: 14px;
      border: 2px solid var(--c-green-1);
    }
    &._isDisabled {
      color: var(--c-gray-1);
      border: 2px solid var(--c-gray-1);
      @media (min-width: 751px) {
        border: 2px solid var(--c-gray-1);
      }
    }
  }
}
.formWrapper {
  width: 100vw;
}
.section7 {
  z-index: 1;
}
</style>
