<template>
  <div class="TopPagePricingTablePc">
    <div class="TopPagePricingTablePc_Inner">
      <h3>プラン別機能比較</h3>
      <div class="TopPagePricingTablePc_Anchor">
        <p :class="{ active: currentTab === 0 }" @click="currentTab = 0">
          <img src="/img/pricing-personal.png" />
          <span>basic</span>
        </p>
        <p :class="{ active: currentTab === 1 }" @click="currentTab = 1">
          <img src="/img/pricing-business.png" />
          <span>business</span>
        </p>
      </div>
      <div
        v-for="(list, index) in priceList"
        :key="index"
        class="TopPagePricingTablePc_Table"
      >
        <h4>{{ list.name }}</h4>
        <table>
          <tr>
            <th>機能名</th>
            <th>解説</th>
            <th>{{ currentTab === 0 ? 'Free' : 'Small Team' }}</th>
            <th>{{ currentTab === 0 ? 'Personal' : 'Enterprise' }}</th>
          </tr>
          <tr v-for="(plan, pIndex) in list.details" :key="`plan${pIndex}`">
            <td>{{ plan.title }}</td>
            <td>{{ plan.info }}</td>

            <td v-if="plan.plan[currentTab][0] === '0'">
              <img src="/img/circle.svg" width="18" />
            </td>
            <td v-if="plan.plan[currentTab][0] === '1'">
              <img src="/img/batu.svg" width="14" />
            </td>
            <td
              v-if="
                plan.plan[currentTab][0] !== '0' &&
                  plan.plan[currentTab][0] !== '1'
              "
            >
              {{ plan.plan[currentTab][0] }}
            </td>
            <td v-if="plan.plan[currentTab][1] === '0'">
              <img src="/img/circle.svg" width="18" />
            </td>
            <td v-if="plan.plan[currentTab][1] === '1'">
              <img src="/img/batu.svg" width="14" />
            </td>
            <td
              v-if="
                plan.plan[currentTab][1] !== '0' &&
                  plan.plan[currentTab][1] !== '1'
              "
            >
              {{ plan.plan[currentTab][1] }}
            </td>
          </tr>
        </table>
      </div>
      <TopPageButton>
        <template #content>
          <a
            class="TopPagePricingTablePc_Btn"
            @click="$emit('priceClose', true)"
          >
            閉じる
          </a>
        </template>
      </TopPageButton>
    </div>
  </div>
</template>
<script>
import TopPageButton from './TopPageButton.vue'

export default {
  components: {
    TopPageButton
  },
  data() {
    return {
      responsive: false,
      currentTab: 0
    }
  },
  computed: {
    priceList() {
      return require('../static/data/prices.json')
    }
  }
}
</script>
<style lang="postcss" scoped>
.TopPagePricingTablePc {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(33, 33, 33, 0.8);
  @media screen and (min-width: 751px) {
    padding: 72px 0;
  }
  &_Inner {
    overflow: scroll;
    @media screen and (min-width: 751px) {
      width: 1209px;
      padding: 69px 105px 35px;
      background: #000;
      border-radius: 100px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &_Btn {
    cursor: pointer;
  }
  h3 {
    @media screen and (min-width: 751px) {
      font-size: 40px;
      font-weight: bold;
      text-align: center;
    }
  }
  &_Anchor {
    display: flex;
    justify-content: center;
    @media screen and (min-width: 751px) {
      width: 320px;
      margin: 28px auto;
    }
    p {
      position: relative;
      opacity: 0.1;
      span {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: 751px) {
          font-size: 23px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.1em;
          cursor: pointer;
        }
      }
      &.active {
        opacity: 1;
      }
    }
  }
  h4 {
    @media screen and (min-width: 751px) {
      padding: 10px 0;
      margin-bottom: 29px;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      border-bottom: 2px solid #424242;
    }
  }
  table {
    width: 100%;
    @media screen and (min-width: 751px) {
      margin-bottom: 53px;
    }
    th {
      @media screen and (min-width: 751px) {
        padding: 16px 0;
        font-size: 12px;
        font-weight: bold;
      }
    }
    tr {
      &:nth-child(even) {
        background: #2b2b2b;
      }
      @media screen and (min-width: 751px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    td {
      position: relative;
      text-align: center;
      @media screen and (min-width: 751px) {
        width: calc(458px / 3);
        padding: 17px;
      }
      &:after {
        position: absolute;
        top: 50%;
        right: 0;
        content: '';
        background: #212121;
        transform: translateY(-50%);
        @media screen and (min-width: 751px) {
          width: 1px;
          height: 25px;
        }
      }
      &:first-child {
        text-align: left;
        @media screen and (min-width: 751px) {
          width: 229px;
        }
      }
      &:nth-child(2) {
        color: #888;
        text-align: left;
        @media screen and (min-width: 751px) {
          width: 313px;
        }
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      span {
        color: #888;
      }
    }
    img {
      display: inline;
      margin: auto;
    }
    [src$='.svg'] {
      width: auto;
    }
  }
  .ButtonComponent {
    @media screen and (min-width: 751px) {
      width: 158px;
      margin: 70px auto 0;
    }
  }
}
</style>
