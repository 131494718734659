var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TopMain"},[_c('div',[_c('client-only',[_c('div',{attrs:{"id":"fullpage"}},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"TopMain_Wrapper"},[_c('top-page-main-visual')],1)]),_vm._v(" "),_c('div',{staticClass:"section sectionLink fp-auto-height skip"},[_c('div',{staticClass:"formWrapper"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.scan)}}},[_c('ValidationProvider',{attrs:{"name":"URL","rules":"required|url-service-scan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"LinkCreate"},[_c('img',{attrs:{"src":"/img/link-icon.png"}}),_vm._v(" "),_c('div',{staticClass:"LinkCreate_Block"},[_c('div',{staticClass:"LinkCreate_BlockText"},[_c('p',[_vm._v("\n                          会員登録ナシ"),_c('span',[_vm._v("で"),_c('span',{staticClass:"pc"},[_vm._v("まずは")])]),_vm._v("お試し！"),_c('br',{staticClass:"sp"}),_vm._v("簡単リンク作成\n                        ")]),_vm._v(" "),_c('button',{staticClass:"TopMain_ButtonCreateSp",attrs:{"type":"button"},on:{"click":function($event){_vm.linkCreateSp = true}}},[_vm._v("\n                          リンクを"),_c('br'),_vm._v("試す\n                        ")])]),_vm._v(" "),(!_vm.responsive)?_c('div',{staticClass:"LinkCreate_FormBlock"},[_c('div',{staticClass:"LinkCreate_BlockForm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.serviceUrl),expression:"serviceUrl"}],staticClass:"TopMain_InputElement",attrs:{"type":"text","placeholder":"好きな曲のSpotify / Apple MusicのURLを入力するだけ"},domProps:{"value":(_vm.serviceUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.serviceUrl=$event.target.value}}}),_vm._v(" "),_c('button',{staticClass:"TopMain_ButtonCreate",attrs:{"type":"submit"}},[_vm._v("\n                            作成\n                          ")])]),_vm._v(" "),_c('p',{staticClass:"TopMain_InputError"},[_vm._v("\n                          "+_vm._s(errors[0])+"\n                        ")])]):_vm._e()])])]}}],null,true)})],1)]}}])})],1)]),_vm._v(" "),_c('div',{staticClass:"section TopMain_Wrapper skipNext"},[_c('top-page-about')],1),_vm._v(" "),_c('div',{staticClass:"section TopMain_Wrapper",class:{ 'fp-auto-height': _vm.responsive }},[_c('top-page-function',{on:{"popupShow":function($event){return _vm.showPopup(true)},"priceTableOpen":function($event){_vm.priceTable = true
              _vm.priceTableOpen = true}}})],1),_vm._v(" "),_c('div',{staticClass:"section TopMain_Wrapper",class:{ 'fp-auto-height': _vm.responsive }},[_c('top-page-pricing',{on:{"popupShow":_vm.showPopupPrice,"priceTableOpen":function($event){_vm.priceTable = true}}})],1),_vm._v(" "),_c('div',{staticClass:"section TopMain_Wrapper"},[_c('top-page-statement',{attrs:{"responsive":_vm.responsive},on:{"rebuild":_vm.fpRebuild}})],1),_vm._v(" "),_c('div',{staticClass:"section TopMain_Wrapper section7"},[_c('top-page-topics',{attrs:{"note":_vm.noteData}})],1),_vm._v(" "),_c('div',{staticClass:"section fp-auto-height"},[_c('TheTeaserFooter')],1)])]),_vm._v(" "),_c('div',{staticClass:"TopMain_ScrollMark"},[_vm._v("Scroll")]),_vm._v(" "),(_vm.popup)?[_c('top-page-function-slide',{on:{"functionPopupClose":function($event){return _vm.showPopup(false)},"showFunction":function($event){_vm.showPopup(false)
          _vm.priceTable = true}}})]:_vm._e(),_vm._v(" "),(_vm.popupPrice)?[_c('top-page-pricing-slide',{attrs:{"price-type":_vm.priceType},on:{"priceTableOpen":function($event){_vm.priceTable = true},"pricingPopupClose":function($event){_vm.popupPrice = false}}})]:_vm._e()],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],staticClass:"TopMain_ModalWrapper"},[_c('div',{staticClass:"TopMain_ModalAddCard"},[_c('div',{staticClass:"TopMain_ModalHead"},[_vm._v("\n        リンクページの確認\n      ")]),_vm._v(" "),_c('div',{staticClass:"TopMain_ModalBody"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"TopMain_ModalLinkWrapper"},[_c('a',{staticClass:"TopMain_ModalLink",attrs:{"href":_vm.newUrl,"target":"_blank"}},[_vm._v("\n            "+_vm._s(_vm.newUrl)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"TopMain_ModalIframeWrapper"},[_c('p',{staticClass:"TopMain_ModalIframeText"},[_vm._v("埋め込みコード：")]),_vm._v(" "),_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.iframe),expression:"iframe",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copiedSuccess),expression:"copiedSuccess",arg:"success"}],staticClass:"TopMain_ModalIframe"},[_c('code',{staticClass:"TopMain_ModalCode"},[_vm._v("\n              "+_vm._s(_vm.iframe)+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"TopMain_ModalButtonWrapper"},[_c('button',{staticClass:"TopMain_ModalCancel",on:{"click":function($event){_vm.showModal = false
              _vm.serviceUrl = ''}}},[_vm._v("\n            閉じる\n          ")]),_vm._v(" "),(_vm.data && _vm.data.linkId)?_c('n-link',{staticClass:"TopMain_ModalSubmit",attrs:{"to":{
              path: ("/non-registered/edit?linkId=" + (_vm.data.linkId) + (_vm.$device.isDesktop ? '&isPreviewLink=true' : ''))
            }}},[_vm._v("\n            編集する\n          ")]):_vm._e()],1)])])]),_vm._v(" "),_c('client-only',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.responsive && _vm.linkCreateSp),expression:"responsive && linkCreateSp"}],staticClass:"TopMain_LinkCreateSp"},[_c('div',{staticClass:"TopMain_LinkCreateSpBlock"},[_c('img',{attrs:{"src":"/img/link-icon.png"}}),_vm._v(" "),_c('p',[_vm._v("\n          好きな曲のSpotify / Apple\n          MusicのURLを入力して作成ボタンを押してください。\n        ")]),_vm._v(" "),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.scan)}}},[_c('ValidationObserver',{attrs:{"name":"URL","rules":"required|url-service-scan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.serviceUrl),expression:"serviceUrl"}],staticClass:"TopMain_InputElement",attrs:{"type":"text","placeholder":"URLを入力"},domProps:{"value":(_vm.serviceUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.serviceUrl=$event.target.value}}}),_vm._v(" "),_c('p',{staticClass:"TopMain_InputError"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]),_vm._v(" "),_c('button',{staticClass:"TopMain_ButtonCreate",attrs:{"type":"submit"}},[_vm._v("\n                作成\n              ")])]}}],null,true)})],1)]}}])}),_vm._v(" "),_c('Button',{staticClass:"TopPage_LinkCreateSpClose",on:{"click":function($event){_vm.linkCreateSp = false}}},[_vm._v("\n          閉じる\n        ")])],1)]),_vm._v(" "),(!_vm.responsive && _vm.priceTable)?_c('top-page-pricing-table-pc',{on:{"priceClose":function($event){_vm.priceTable = false}}}):_vm._e(),_vm._v(" "),(_vm.responsive && _vm.priceTable)?_c('top-page-pricing-table-sp',{on:{"priceClose":function($event){_vm.priceTable = false}}}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"TopMain_ModalText"},[_vm._v("\n          リンクページが作成されました。"),_c('br'),_vm._v("\n          以下のURLからご確認ください。\n        ")])}]

export { render, staticRenderFns }