<template>
  <div class="TopPageFunctionSlider">
    <client-only>
      <carousel-3d
        v-if="!responsive"
        :width="679"
        :height="668"
        :perspective="0"
      >
        <slide-3d :index="0">
          <div class="TopPageFunctionSlider_Slide">
            <h4>
              <img src="/img/function-links.png" width="162" class="width162" />
              <span>B.O.Mのリンク機能でできること</span>
            </h4>
            <div class="TopPageFunctionSlider_Text">
              <h5>リンクページにて一括管理</h5>
              <p>
                各音楽配信ストアの楽曲URLをひとつのリンクで一括管理。ストリーミングやダウンロードのURLを
                1つにまとめて簡単にシェアが可能。
              </p>
              <h5>プレリリースページ作成</h5>
              <p>
                Spotify、Apple Music、iTunesを
                対象にプレリリースページの作成が可能。
                リリースを予約したユーザーは楽曲が配信されると
                自分のプレイヤーのライブラリに曲が自動で追加されます。
              </p>
              <h5>プロフィール編集</h5>
              <p>
                各リンクページのプロフィールをユニークな内容に編集することが可能。
                現状はプロフィール情報やSNS情報、MVの掲載が可能。
              </p>
              <p>
                詳細やその他機能については<a
                  class="TopPageFunctionSlider_Btn"
                  @click="$emit('showFunction', true)"
                  >機能一覧ページ</a
                >へ
              </p>
            </div>
            <TopPageButton class="FunctionPopupClose">
              <template #content>
                <a @click="$emit('functionPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide-3d>
        <slide-3d :index="1">
          <div class="TopPageFunctionSlider_Slide">
            <h4>
              <img src="/img/function-analyse.png" class="width139" />
              <span>B.O.Mの分析機能でできること</span>
            </h4>
            <div class="TopPageFunctionSlider_Text">
              <h5>リンクページを分析</h5>
              <p>
                作成したリンクページがどのくらいのユーザーに見られたのか、どのくらいのユーザが音楽配信ストアに遷移したのか、計測が行えます。
              </p>
              <h5>参照元・地域別分析</h5>
              <p>
                作成したリンクページがどのサイトやメディアから流入があったか、また、日本国内の都道府県および国別といった地域別の計測が行えます。
              </p>
              <h5>計測内容の設定と出力</h5>
              <p>
                目的に合わせて計測する内容と期間を設定することができ、計測したデータをCSVやエクセルに変換し出力することができます。
              </p>
              <p>
                詳細やその他機能については<a
                  class="TopPageFunctionSlider_Btn"
                  @click="$emit('showFunction', true)"
                  >機能一覧ページ</a
                >へ
              </p>
            </div>
            <TopPageButton class="FunctionPopupClose">
              <template #content>
                <a @click="$emit('functionPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide-3d>
        <slide-3d :index="2">
          <div class="TopPageFunctionSlider_Slide">
            <h4>
              <img src="/img/function-promote.png" class="width144" />
              <span>B.O.Mのプロモーション機能でできること</span>
            </h4>
            <div class="TopPageFunctionSlider_Text">
              <p>
                音楽マーケティングを行う上での制作、広告、PRといったあらゆるコミュニケーション活動を支援する機能。現在はキャンペーンページ制作機能を実装、提供。
              </p>
              <h5>キャンペーンページ制作</h5>
              <p>
                CMS（フォーマットを提供）による制作のため、誰でも簡単にレイアウトやテキストの調整ができ、キャンペーンページを制作することができます。<br />
                ※法人向けにスクラッチ制作（Custom madeプラン）も用意。Custom
                madeの詳細については<a
                  href="https://form.run/@bom--1592908099"
                  target="_blank"
                  >お問い合わせ</a
                >くださいませ。
              </p>
            </div>
            <TopPageButton class="FunctionPopupClose">
              <template #content>
                <a @click="$emit('functionPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide-3d>
      </carousel-3d>
      <carousel
        v-if="responsive"
        :per-page="1"
        :navigate-to="currentSlide"
        :adjustable-height="true"
        :navigation-enabled="false"
        :pagination-enabled="false"
        @page-change="(e) => changeSlideOnSwap(e)"
      >
        <slide>
          <div class="TopPageFunctionSlider_Slide">
            <h4 class="spTitle">
              <img src="/img/function-links.png" />
              <span>B.O.Mのリンク機能でできること</span>
            </h4>
            <TopPageSlideNavigator
              :current="currentSlide"
              slide-count="3"
              slide-index="0"
              @changeCurrent="(e) => changeSlide(e)"
            />
            <div class="TopPageFunctionSlider_Text">
              <h5>リンクページにて一括管理</h5>
              <p>
                各音楽配信ストアの楽曲URLをひとつのリンクで一括管理。ストリーミングやダウンロードのURLを
                1つにまとめて簡単にシェアが可能。
              </p>
              <h5>プレリリースページ作成</h5>
              <p>
                Spotify、Apple Music、iTunesを
                対象にプレリリースページの作成が可能。
                リリースを予約したユーザーは楽曲が配信されると
                自分のプレイヤーのライブラリに曲が自動で追加されます。
              </p>
              <h5>プロフィール編集</h5>
              <p>
                各リンクページのプロフィールをユニークな内容に編集することが可能。
                現状はプロフィール情報やSNS情報、MVの掲載が可能。
              </p>
              <p>
                詳細やその他機能については<a
                  class="TopPageFunctionSlider_Btn"
                  @click="$emit('showFunction', true)"
                  >機能一覧ページ</a
                >へ
              </p>
            </div>
            <TopPageButton class="FunctionPopupClose">
              <template #content>
                <a @click="$emit('functionPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide>
        <slide>
          <div class="TopPageFunctionSlider_Slide">
            <h4 class="spTitle">
              <img src="/img/function-analyse.png" />
              <span>B.O.Mの分析機能でできること</span>
            </h4>
            <TopPageSlideNavigator
              :current="currentSlide"
              slide-count="3"
              slide-index="1"
              @changeCurrent="(e) => changeSlide(e)"
            />
            <div class="TopPageFunctionSlider_Text">
              <h5>リンクページを分析</h5>
              <p>
                作成したリンクページがどのくらいのユーザーに見られたのか、どのくらいのユーザが音楽配信ストアに遷移したのか、計測が行えます。
              </p>
              <h5>参照元・地域別分析</h5>
              <p>
                作成したリンクページがどのサイトやメディアから流入があったか、また、日本国内の都道府県および国別といった地域別の計測が行えます。
              </p>
              <h5>計測内容の設定と出力</h5>
              <p>
                目的に合わせて計測する内容と期間を設定することができ、計測したデータをCSVやエクセルに変換し出力することができます。
              </p>
              <p>
                詳細やその他機能については<a
                  class="TopPageFunctionSlider_Btn"
                  @click="$emit('showFunction', true)"
                  >機能一覧ページ</a
                >へ
              </p>
            </div>
            <TopPageButton class="FunctionPopupClose">
              <template #content>
                <a @click="$emit('functionPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide>
        <slide>
          <div class="TopPageFunctionSlider_Slide">
            <h4 class="spTitle">
              <img src="/img/function-promote.png" />
              <span>B.O.Mのプロモーション機能でできること</span>
            </h4>
            <TopPageSlideNavigator
              :current="currentSlide"
              slide-count="3"
              slide-index="2"
              @changeCurrent="(e) => changeSlide(e)"
            />
            <div class="TopPageFunctionSlider_Text">
              <p>
                音楽マーケティングを行う上での制作、広告、PRといったあらゆるコミュニケーション活動を支援する機能。現在はキャンペーンページ制作機能を実装、提供。
              </p>
              <h5>キャンペーンページ制作</h5>
              <p>
                CMS（フォーマットを提供）による制作のため、誰でも簡単にレイアウトやテキストの調整ができ、キャンペーンページを制作することができます。<br />
                ※法人向けにスクラッチ制作（Custom madeプラン）も用意。Custom
                madeの詳細については<a href="/">お問い合わせ</a>くださいませ。
              </p>
            </div>
            <TopPageButton class="TopPageFunctionSlider_Close">
              <template #content>
                <a @click="$emit('functionPopupClose', true)">
                  閉じる
                </a>
              </template>
            </TopPageButton>
          </div>
        </slide>
      </carousel>
    </client-only>
  </div>
</template>
<script>
import TopPageButton from './TopPageButton.vue'
import TopPageSlideNavigator from './TopPageSlideNavigator.vue'

export default {
  components: {
    TopPageButton,
    TopPageSlideNavigator
  },
  data() {
    return {
      responsive: false,
      currentSlide: 0,
      slideNum: 3
    }
  },
  mounted() {
    if (process.browser) {
      if (window.innerWidth < 751) {
        this.responsive = true
      }
      setTimeout(() => {
        this.applyCurrentSlideHeight()
      }, 100)
    }
  },
  methods: {
    changeSlide(e) {
      if (e && this.currentSlide <= this.slideNum - 2) {
        this.currentSlide += 1
      }
      if (!e && this.currentSlide > 0) {
        this.currentSlide -= 1
      }
      this.applyCurrentSlideHeight()
    },
    changeSlideOnSwap(e) {
      this.currentSlide = e
      this.applyCurrentSlideHeight()
    },
    applyCurrentSlideHeight() {
      const current = document.getElementsByClassName(
        'VueCarousel-slide-active'
      )[0]
      const currentSlide =
        current || document.getElementsByClassName('VueCarousel-slide')[0]
      const slideHeight = document.getElementsByClassName(
        'VueCarousel-inner'
      )[0]
      if (slideHeight === currentSlide.offsetHeight) {
        return
      }
      slideHeight.style.height = `${currentSlide.offsetHeight + 1}px`
    }
  }
}
</script>
<style lang="postcss" scoped>
.TopPageFunctionSlider {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  padding: 27px 0;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: rgba(33, 33, 33, 1);
  @media screen and (min-width: 751px) {
    top: 0;
    left: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  &_Slide {
    position: relative;
    padding: 41px 24px 51px;
    margin: 0 24px;
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(33, 33, 33, 1) 100%
    );
    border-radius: 100px;
    @media screen and (min-width: 751px) {
      width: 679px;
      height: 668px;
      margin: auto;
      padding: 30px 70px;
      border-radius: 100px;
    }
  }
  &_Text {
    margin-bottom: 43px;
    @media screen and (min-width: 751px) {
      margin-bottom: 0;
    }
  }
  &_Btn {
    cursor: pointer;
  }
  h4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.1em;
    @media screen and (min-width: 751px) {
      flex-direction: row;
      margin-bottom: 0;
      font-size: 23px;
      text-align: left;
      letter-spacing: 3px;
    }
    img {
      @media screen and (max-width: 750px) {
        max-height: 139px;
      }
    }
  }
  h5 {
    margin: 23px 0 8px;
    font-size: 16px;
    font-weight: bold;
    @media screen and (min-width: 751px) {
      margin: 0 0 13px;
      font-size: 18px;
    }
  }
  p {
    font-size: 12px;
    line-height: 2;
    @media screen and (min-width: 751px) {
      margin-bottom: 33px;
      font-size: 12px;
      line-height: 2;
    }
    a {
      color: #ffc107;
      text-decoration: underline;
    }
  }
  &_Close {
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
    @media screen and (min-width: 751px) {
      position: absolute;
      bottom: 40px;
      a {
        width: 158px;
        cursor: pointer;
      }
    }
    a {
      width: 158px;
      height: 30px;
      padding: 0;
      margin: auto;
      font-size: 12px;
      line-height: 30px;
      border-radius: 5px;
      @media screen and (min-width: 751px) {
        width: 158px;
        height: auto;
        padding: 0;
        font-size: 12px;
        line-height: 30px;
        cursor: pointer;
        border-radius: 5px;
      }
    }
  }
}

.carousel-3d {
  &-slide {
    background: none;
    border-color: transparent;
  }
  &-container,
  &-slider,
  &-slide {
    height: auto !important;
  }
}
.width162 {
  @media screen and (min-width: 751px) {
    width: 162px !important;
  }
}
.width144 {
  @media screen and (min-width: 751px) {
    width: 144px !important;
  }
}
.width139 {
  @media screen and (min-width: 751px) {
    width: 162px !important;
  }
}
</style>
