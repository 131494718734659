<template>
  <div class="TopPagePricingTableSp">
    <div class="TopPagePricingTableSp_Table">
      <div class="TopPagePricingTableSp_Anchor">
        <p :class="{ active: currentTab === 0 }" @click="currentTab = 0">
          <img src="/img/pricing-personal.png" />
          <span>basic</span>
        </p>
        <p :class="{ active: currentTab === 1 }" @click="currentTab = 1">
          <img src="/img/pricing-business.png" />
          <span>business</span>
        </p>
      </div>
      <div class="TopPagePricingTableSp_Table">
        <ul class="TopPagePricingTableSp_TableList">
          <li :class="{ active: subTab === 0 }" @click="subTab = 0">
            リンク機能
          </li>
          <li :class="{ active: subTab === 1 }" @click="subTab = 1">
            分析機能
          </li>
          <li :class="{ active: subTab === 2 }" @click="subTab = 2">
            その他
          </li>
        </ul>
        <div v-for="(plan, index) in priceList[subTab].details" :key="index">
          <div class="TopPagePricingTableSp_TableBlock">
            <p>
              <span>{{ plan.title }}</span>
              {{ plan.info }}
            </p>
            <table>
              <tr>
                <th>{{ currentTab === 0 ? 'Free' : 'Small Team' }}</th>
                <th>{{ currentTab === 0 ? 'Personal' : 'Enterprise' }}</th>
              </tr>
              <tr>
                <td v-if="plan.plan[currentTab][0] === '0'">
                  <img src="/img/circle.svg" width="18" />
                </td>
                <td v-if="plan.plan[currentTab][0] === '1'">
                  <img src="/img/batu.svg" width="14" />
                </td>
                <td
                  v-if="
                    plan.plan[currentTab][0] !== '0' &&
                      plan.plan[currentTab][0] !== '1'
                  "
                >
                  {{ plan.plan[currentTab][0] }}
                </td>
                <td v-if="plan.plan[currentTab][1] === '0'">
                  <img src="/img/circle.svg" width="18" />
                </td>
                <td v-if="plan.plan[currentTab][1] === '1'">
                  <img src="/img/batu.svg" width="14" />
                </td>
                <td
                  v-if="
                    plan.plan[currentTab][1] !== '0' &&
                      plan.plan[currentTab][1] !== '1'
                  "
                >
                  {{ plan.plan[currentTab][1] }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <ul class="TopPagePricingTableSp_TableList btm">
          <li :class="{ active: subTab === 0 }" @click="subTab = 0">
            リンク機能
          </li>
          <li :class="{ active: subTab === 1 }" @click="subTab = 1">
            分析機能
          </li>
          <li :class="{ active: subTab === 2 }" @click="subTab = 2">
            その他
          </li>
        </ul>
      </div>

      <div class="TopPagePricingTableSp_Anchor btm">
        <p :class="{ active: currentTab === 0 }" @click="currentTab = 0">
          <img src="/img/pricing-personal.png" />
          <span>basic</span>
        </p>
        <p :class="{ active: currentTab === 1 }" @click="currentTab = 1">
          <img src="/img/pricing-business.png" />
          <span>business</span>
        </p>
      </div>
      <TopPageButton>
        <template #content>
          <a @click="$emit('priceClose', true)">
            閉じる
          </a>
        </template>
      </TopPageButton>
    </div>
  </div>
</template>
<script>
import TopPageButton from './TopPageButton.vue'

export default {
  components: {
    TopPageButton
  },
  data() {
    return {
      responsive: false,
      currentTab: 0,
      subTab: 0
    }
  },
  computed: {
    priceList() {
      return require('../static/data/prices.json')
    }
  },
  mounted() {
    console.log(this.priceList)
  }
}
</script>
<style lang="postcss" scoped>
.TopPagePricingTableSp {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(33, 33, 33, 0.8);
  @media screen and (min-width: 751px) {
    padding: 72px 0;
  }
  &_Table {
    width: 327px;
    padding: 49px 0 54px;
    overflow: scroll;
    background: #000;
    border-radius: 100px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &_Anchor {
    display: flex;
    justify-content: center;
    padding: 0 27px;
    margin-bottom: 39px;
    p {
      position: relative;
      opacity: 0.1;
      span {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 23px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        cursor: pointer;
      }
      &.active {
        opacity: 1;
      }
    }
    img {
      height: 110px;
    }
  }
  &_Table {
    &List {
      display: flex;
      justify-content: center;
      margin-bottom: 36px;
      li {
        min-width: 80px;
        padding: 5px;
        margin-right: 11px;
        font-size: 13px;
        color: #888;
        text-align: center;
        border: 2px solid #2b2b2b;
        border-radius: 100px;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          color: #fff;
          border: 2px solid #fff;
        }
      }
      &.btm {
        margin: 35px auto;
      }
    }
    &Block {
      margin-bottom: 17px;
      p {
        padding: 0 14px;
        font-size: 12px;
        line-height: 20px;
        color: #888;
        span {
          display: block;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
  table {
    width: 100%;
    margin-top: 8px;
    @media screen and (min-width: 751px) {
      margin-bottom: 53px;
    }
    th {
      width: 50%;
      padding: 4px 0;
      font-size: 12px;
      font-weight: bold;
      line-height: 17px;
      letter-spacing: 0.1em;
      background: #424242;
    }
    td {
      position: relative;
      padding: 16px 0;
      font-size: 12px;
      text-align: center;
      background: #2b2b2b;
      &:first-child {
        &:after {
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 32px;
          content: '';
          background: #212121;
          transform: translateY(-50%);
        }
      }
    }
    img {
      display: inline;
      margin: auto;
    }
    [src$='.svg'] {
      width: auto;
    }
  }
  .ButtonComponent {
    margin: auto;
    a {
      width: 158px;
      height: 30px;
    }
  }
}
</style>
